import { ContentfulTeacher } from "../../../../graphql/types"
import { PathPrefix } from "../../../core/constants"
import { mapSingleTeacher } from "../../../core/mappers/map-teachers"
import { mapMetadata } from "../../../core/mappers/map-metadata"
import { mapNestedTopics } from "../../../core/mappers/map-nested-topics"
import { TypedFragment } from "../../../types/graphql"
import {
  CollectionItemMappingFunction,
  CollectionItem,
  CollectionDetails
} from "../collection-types"
import { ContentfulQuestionAnswer } from "../../../types/graphql/contentful/question-answer"
import { ContentfulMediaResource } from "../../../types/graphql/contentful/media-resource"
import {
  ContentfulCollection,
  ContentfulCollectionItem
} from "../../../types/graphql/contentful/collection"
import { ContentfulExternalLink } from "../../../types/graphql/contentful/external-link"
import { ContentfulPodcastShow } from "../../../types/graphql/contentful/podcast-show"
import { ContentfulPodcastEpisode } from "../../../types/graphql/contentful/podcast-episode"
import { ContentfulSeries } from "../../../types/graphql/contentful/series"
import { ContentfulDevotional } from "../../../types/graphql/contentful/devotional"
import { ContentfulArticle } from "../../../types/graphql/contentful/article"
import { ContentfulSermon } from "../../../types/graphql/contentful/sermon"
import { ContentfulPost } from "../../../types/graphql/contentful/post"
import { ContentfulAsset } from "@src-types/graphql/contentful/core"

export const mapSeriesToCollectionItem = (
  input: TypedFragment<ContentfulSeries>
): CollectionItem => {
  const prefixPaths = {
    "Teaching Series": PathPrefix.Series,
    "Sermon Series": PathPrefix.Sermon,
    "Conference Messages": PathPrefix.Conferences
  }

  let prefixSlug = PathPrefix.Series

  if (input.seriesType && prefixPaths[input.seriesType]) {
    prefixSlug = prefixPaths[input.seriesType]
  }

  return {
    type: "series",
    image: (input.image as ContentfulAsset)?.fluid?.src || "",
    link: `${prefixSlug}/${input.slug}`,
    slug: input.slug || "",
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    title: input.name,
    readingTime: 0
  }
}

export const mapArticleToCollectionItem = (
  input: ContentfulArticle
): CollectionItem => {
  return {
    type: "article",
    image: input.headerImage?.fluid?.src || "",
    link: `${PathPrefix.Articles}/${input.slug}`,
    slug: input.slug || "",
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    title: input.name,
    readingTime: input.extendedCopy
      ? input.extendedCopy.reduce((acc, cur) => {
          const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

          return time + acc
        }, 0)
      : input.bodyCopy?.childMarkdownRemark?.timeToRead || 0
  }
}

export const mapDevotionalToCollectionItem = (
  input: TypedFragment<ContentfulDevotional>
): CollectionItem => {
  return {
    image: "",
    link: `${PathPrefix.Devotional}/${input.slug}`,
    title: input.name || "",
    scriptureReference: input.primaryScriptureReference,
    slug: input.slug || "",
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    type: "devotional"
  }
}

export const mapQuestionAnsweredToCollectionItem = (
  input: TypedFragment<ContentfulQuestionAnswer>
): CollectionItem => {
  return {
    type: input.typeForIcon,
    slug: input.slug,
    title: input.question || "",
    link: `${PathPrefix.QuestionsAnswered}/${input.slug}`,
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    image: input.video?.image?.fluid?.src || "",
    readingTime: 0
  }
}

export const mapMediaResourceToCollectionItem = (
  input: TypedFragment<ContentfulMediaResource>
): CollectionItem => {
  const prefixPaths = {
    "Teaching Series": PathPrefix.Series,
    "Sermon Series": PathPrefix.Sermon,
    "Conference Messages": PathPrefix.Conferences
  }

  const seriesTypeKey = input.series.seriesType || "Teaching Series"

  return {
    type: "mediaResource",
    slug: input.slug,
    title: input.name,
    link: `${prefixPaths[seriesTypeKey]}/${input.series.slug}/${input.slug}`,
    image: input?.video?.image?.fluid?.src || "",
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    readingTime: 0
  }
}

export const mapPostToCollectionItem = (
  input: ContentfulPost
): CollectionItem => {
  return {
    type: "post",
    image: input.headerImage?.fluid?.src || "",
    link: `${PathPrefix.Post}/${input.slug}`,
    slug: input.slug || "",
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    title: input.name,
    readingTime: input.extendedCopy
      ? input.extendedCopy.reduce((acc, cur) => {
          const time = cur?.bodyCopy?.childMarkdownRemark?.timeToRead || 0

          return time + acc
        }, 0)
      : input.bodyCopy?.childMarkdownRemark?.timeToRead || 0
  }
}

export const mapCollectionToCollectionItem = (
  input: TypedFragment<ContentfulCollection>
): CollectionItem => {
  return {
    type: "collection",
    slug: input.slug,
    title: input.title,
    link: `${PathPrefix.Collections}/${input.slug}`,
    image: input.headerImage?.fluid?.src || "",
    teachers: [],
    readingTime: 0
  }
}

export const mapExternalLinkToCollectionItem = (
  input: TypedFragment<ContentfulExternalLink>
): CollectionItem => {
  return {
    type: "externalLink",
    slug: input.url,
    title: input.title,
    link: input.url,
    image: input.image?.fluid?.src || "",
    subtitle: input.authors ? undefined : input.author,
    teachers: (input.authors || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    readingTime: 0
  }
}

export const mapContentfulPodcastShowToCollectionItem = (
  input: TypedFragment<ContentfulPodcastShow>
): CollectionItem => {
  return {
    type: "podcastShow",
    slug: input.slug,
    title: input.title,
    link: `${PathPrefix.Podcasts}/${input.slug}`,
    image: input?.image?.fluid?.src || "",
    teachers: (input.hosts || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    readingTime: 0
  }
}

export const mapContentfulPodcastEpisodeToCollectionItem = (
  input: TypedFragment<ContentfulPodcastEpisode>
): CollectionItem => {
  return {
    type: "podcastEpisode",
    slug: input.slug,
    title: input.title,
    link: `${PathPrefix.Podcasts}/${input?.podcastShow?.slug}/${input?.slug}`,
    image: input?.podcastShow?.image?.fluid?.src || "",
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    readingTime: 0
  }
}

export const mapSermonToCollectionItem = (
  input: TypedFragment<ContentfulSermon>
): CollectionItem => {
  return {
    type: "sermon",
    slug: input.slug,
    title: input.sermonTitle,
    link: `${PathPrefix.Sermon}/${input.slug}`,
    image: "",
    teachers: (input.teachers || []).map((t) =>
      mapSingleTeacher(t as ContentfulTeacher)
    ),
    readingTime: 0
  }
}

export const collectionItemMappingFunctions: Record<
  string,
  CollectionItemMappingFunction
> = {
  ContentfulArticle: mapArticleToCollectionItem,
  ContentfulCollection: mapCollectionToCollectionItem,
  ContentfulDevotional: mapDevotionalToCollectionItem,
  ContentfulExternalLink: mapExternalLinkToCollectionItem,
  ContentfulMediaResource: mapMediaResourceToCollectionItem,
  ContentfulPodcastEpisode: mapContentfulPodcastEpisodeToCollectionItem,
  ContentfulPodcastShow: mapContentfulPodcastShowToCollectionItem,
  ContentfulPost: mapPostToCollectionItem,
  ContentfulQA: mapQuestionAnsweredToCollectionItem,
  ContentfulSeries: mapSeriesToCollectionItem,
  ContentfulSermon: mapSermonToCollectionItem
}

export function mapCollectionItemToView(
  input: TypedFragment<ContentfulCollectionItem>,
  mappingFunctions: Record<string, CollectionItemMappingFunction>
): CollectionItem {
  const mappingFunction = mappingFunctions[input.__typename]

  return mappingFunction?.(input) || null
}

export function mapCollectionDetailsToView(
  input: ContentfulCollection
): CollectionDetails {
  const metadata = mapMetadata(
    input?.metadata || {},
    {
      title: input?.title,
      description: input?.description?.childMarkdownRemark?.plainText,
      image: input?.headerImage?.fluid?.src
    },
    {
      title:
        input?.pageTitle.trim() + " | Study Reformed Theology at Ligonier.org"
    }
  )

  return {
    title: input?.title || "",
    topics: mapNestedTopics(input?.primaryTopic),
    description: input?.description?.childMarkdownRemark?.rawMarkdownBody || "",
    id: input?.id || "",
    slug: input?.slug || "",
    items: (input?.collectionItems || [])
      .map((i) =>
        mapCollectionItemToView(
          i as TypedFragment<ContentfulCollectionItem>,
          collectionItemMappingFunctions
        )
      )
      .filter(Boolean),
    metadata
  }
}
