import { promoCollectionHandle } from "../collections-constants"
import { CollectionItem } from "../collection-types"
import { Store } from "../../../core/constants"

const storefrontDomain = process.env.GATSBY_STOREFRONT_DOMAIN || ""
const storefrontAccessToken = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN || ""
const headers = {
  accept: "application/json",
  "accept-language": "*",
  "content-type": "application/json",
  "sec-fetch-dest": "empty",
  "sec-fetch-mode": "cors",
  "sec-fetch-site": "cross-site",
  "x-sdk-variant": "javascript",
  "x-sdk-version": "2.11.0",
  "x-shopify-storefront-access-token": storefrontAccessToken
}

interface ImageNode {
  node: {
    originalSrc: string
  }
}

interface Product {
  node: {
    handle: string
    title: string
    images: {
      edges: ImageNode[]
    }
  }
}

interface Collection {
  id: string
  handle: string
  title: string
  products: {
    edges: Product[]
  }
}

export const query = `
query ($handle: String!) {
  collectionByHandle(handle: $handle) {
    id
    handle
    title
    products(first: 75) {
      edges {
        node {
          handle
          title
          images(first: 1) {
            edges {
              node {
                originalSrc
              }
            }
          }
        }
      }
    }
  }
}
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCollection = async (): Promise<CollectionItem[] | null> => {
  const handle = promoCollectionHandle
  const res = await window.fetch(
    `https://${storefrontDomain}/api/2020-07/graphql`,
    {
      headers,
      body: JSON.stringify({
        query,
        variables: { handle }
      }),
      method: "POST",
      mode: "cors",
      credentials: "omit"
    }
  )

  if (!res.ok) {
    return null
  }

  const parsed = await res.json()

  if (!parsed.data?.collectionByHandle) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const collection = parsed.data.collectionByHandle as Collection

  const products = collection.products.edges.map<CollectionItem>(({ node }) => {
    return {
      title: node.title,
      link: `${Store}/${node.handle}`,
      image: node.images?.edges?.[0].node.originalSrc || "",
      readingTime: 0,
      slug: node.handle,
      teachers: [],
      type: "product"
    }
  })

  return products
}
