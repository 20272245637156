import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { CollectionPageQuery } from "../../../../graphql/types"
import { PageProps } from "../../../types/page"
import { CollectionPage } from "../../../features/collections/components/collection-page/collection-page"
import { mapCollectionDetailsToView } from "../../../features/collections/mappers/collection-mappers"
import { ContentfulCollection } from "../../../types/graphql/contentful/collection"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { defaultMetadata } from "@core/constants"
import { mapMetadata } from "@core/mappers/map-metadata"
import { MetadataProps } from "@components/metadata/metadata"

export const Collection: FunctionComponent<PageProps<CollectionPageQuery>> = ({
  data,
  ...props
}) => {
  const collectionNode = data?.contentfulCollection
  const { metadata: collectionMetadata } = collectionNode || {}

  if (!collectionNode) return null

  const collection = mapCollectionDetailsToView(
    collectionNode as unknown as ContentfulCollection
  )

  const metadata: MetadataProps = {
    title:
      collectionMetadata?.title ||
      collectionNode.title ||
      defaultMetadata.title,
    description:
      collectionMetadata?.description ||
      collectionNode.description?.childMarkdownRemark?.plainText ||
      defaultMetadata.description,
    openGraphImage:
      collectionMetadata?.image?.fixed?.src ||
      collectionNode.headerImage?.fixed?.src ||
      defaultMetadata.openGraphImage
  }

  return (
    <Page
      {...props}
      metadata={metadata}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Collections,
          {
            label: collection.title,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <CollectionPage collection={collection} />
    </Page>
  )
}

export default Collection

export const query = graphql`
  query CollectionPage($slug: String) {
    contentfulCollection(slug: { eq: $slug }) {
      id
      title
      pageTitle
      slug
      description {
        childMarkdownRemark {
          html
          rawMarkdownBody
          plainText
          timeToRead
        }
      }
      headerImage {
        fixed {
          src
        }
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      primaryTopic {
        ...NestedTopic
      }
      metadata {
        ...Metadata
      }
      collectionItems {
        __typename
        ... on ContentfulArticle {
          ...Article
        }
        ... on ContentfulCollection {
          title
          slug
          image: headerImage {
            fluid {
              src
            }
          }
          internal {
            type
          }
        }
        ... on ContentfulDevotional {
          ...DevotionalDetails
        }
        ... on ContentfulExternalLink {
          title
          author
          authors {
            ...Teacher
          }
          url
          image {
            fixed {
              src
            }
          }
        }
        ... on ContentfulMediaResource {
          slug
          name
          ligCode
          series {
            slug
            seriesType
          }
          video {
            image {
              fixed {
                src
              }
            }
          }
          teachers {
            ...Teacher
          }
        }
        ... on ContentfulPodcastEpisode {
          title
          slug
          teachers {
            ...Teacher
          }
          podcastShow {
            slug
          }
        }
        ... on ContentfulPodcastShow {
          title
          slug
          hosts {
            ...Teacher
          }
          image {
            fixed {
              src
            }
          }
        }
        ... on ContentfulPost {
          ...PostDetails
        }
        ... on ContentfulQA {
          slug
          question
          typeForIcon
          video {
            image {
              fixed {
                src
              }
            }
          }
          teachers {
            ...Teacher
          }
        }
        ... on ContentfulSeries {
          ...Series
        }
        ... on ContentfulSermon {
          sermonTitle
          slug
          teachers {
            ...Teacher
          }
        }
      }
    }
  }
`
