import React, { FunctionComponent } from "react"
import { BreadcrumbResource, Breadcrumbs } from "../breadcrumbs/breadcrumbs"
import { PageHeader, HeaderTitle } from "../generic-page-header"
import {
  ShareAndLikeButton,
  ShareAndLikeButtonProps
} from "../share-and-like-button/share-and-like-button"
import styles from "./centered-header.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface CenteredHeaderProps {
  title: string
  description: string
  breadcrumbs: BreadcrumbResource[]
  shareAndLikeButtonProps?: ShareAndLikeButtonProps
}

export const CenteredHeader: FunctionComponent<CenteredHeaderProps> = ({
  title,
  description,
  breadcrumbs,
  shareAndLikeButtonProps,
  children
}) => (
  <PageHeader className={styles.centeredHeader}>
    <div className={styles.content}>
      {shareAndLikeButtonProps && (
        <ShareAndLikeButton
          className={styles.share}
          {...shareAndLikeButtonProps}
        />
      )}
      <Breadcrumbs items={breadcrumbs} size="sm" />
      <HeaderTitle title={title} />
      {description && (
        <div className={styles.description}>
          <Markdown content={description} />
        </div>
      )}
      {children}
    </div>
  </PageHeader>
)
