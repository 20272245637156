import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from "react"
import { FormattedMessage } from "react-intl"
import {
  CollectionDetails,
  CollectionItem as CollectionItemType
} from "../../collection-types"
import { CollectionItem } from "../collection-item/collection-item"
import { promoCollectionId } from "../../collections-constants"
import { getCollection } from "../collection-helpers"
import styles from "./collection-page.module.scss"
import { CenteredHeader } from "@components/centered-header/centered-header"
import { PathPrefix } from "@core/constants"
import { ShareAndLikeButton } from "@components/share-and-like-button/share-and-like-button"

export interface CollectionPageProps {
  collection: CollectionDetails
}

export const CollectionPage: FunctionComponent<CollectionPageProps> = ({
  collection
}) => {
  const { title, description, topics, totalHours, items } = collection
  const [collectionItems, setCollectionItems] = useState<CollectionItemType[]>()

  const checkItems = useCallback(async () => {
    if (collection.id === promoCollectionId) {
      const newItems = await getCollection()

      if (newItems) {
        setCollectionItems(newItems)
      } else {
        setCollectionItems(items)
      }
    } else {
      setCollectionItems(items)
    }
  }, [collection, items])

  useEffect(() => {
    checkItems()
  }, [checkItems])

  const shareUrl = `${PathPrefix.Collections}/${collection.slug}`

  return (
    <>
      <CenteredHeader
        title={title}
        description={description}
        breadcrumbs={topics}
      >
        <div className={styles.statistics}>
          {collectionItems ? (
            <FormattedMessage
              id="collection-item-count"
              values={{ count: collectionItems.length }}
            />
          ) : null}
          {collection.totalHours && (
            <FormattedMessage
              id="collection-hour-count"
              values={{ count: totalHours || 666 }}
            />
          )}
          <ShareAndLikeButton
            shareUrl={shareUrl}
            id={collection.id}
            contentType="collection"
          />
        </div>
      </CenteredHeader>
      <div className={styles.collectionItems}>
        {collectionItems?.map((i) => (
          <CollectionItem key={i.slug} item={i} />
        ))}
      </div>
    </>
  )
}
