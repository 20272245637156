import { Teacher } from "../../types/teacher"
import { Metadata } from "../../types/metadata"
import { ResourceType } from "../../types/resource"
import { BreadcrumbResource } from "../../components/breadcrumbs/breadcrumbs"
import { IconVariant } from "../../components/icon/icon-types"
import { ScriptureReference } from "../../types/graphql/scripture"

export type CollectionItemType =
  | ResourceType
  | "mediaResource"
  | "collection"
  | "externalLink"
  | "podcastShow"
  | "podcastEpisode"

export const collectionItemIconVariant: Record<
  CollectionItemType,
  IconVariant
> = {
  article: "12-article",
  guide: "12-article",
  collection: "12-collection",
  conference: "12-calendar",
  devotional: "12-book",
  event: "12-calendar",
  externalLink: "12-external-link",
  mediaResource: "12-video",
  podcastEpisode: "12-audio",
  podcastShow: "12-audio",
  post: "12-article",
  product: "12-tag",
  question: "12-audio",
  series: "12-collection",
  sermon: "12-audio",
  video: "12-video"
}

export interface CollectionItem {
  slug: string
  title: string
  subtitle?: string
  link: string
  image: string
  readingTime?: number
  teachers: Teacher[]
  scriptureReference?: ScriptureReference
  type: CollectionItemType
  typeForIcon?: ResourceType
}

export interface CollectionDetails {
  id: string
  title: string
  slug?: string
  description: string
  topics: BreadcrumbResource[]
  items: CollectionItem[]
  metadata: Partial<Metadata>
  totalHours?: number
}

export type CollectionItemMappingFunction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: any // NOTE: I'm blanking out on how to type this properly. The obvious approach has failed miserably.
) => CollectionItem
