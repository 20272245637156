import React, { FunctionComponent } from "react"
import { navigate } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"
import {
  CollectionItem as CollectionItemType,
  collectionItemIconVariant
} from "../../collection-types"
import { ItemImage } from "../../../../components/item-image/item-image"
import { Icon } from "../../../../components/icon/icon"
import { Link } from "../../../../components/link/link"
import { AvatarList } from "../../../../components/avatar/avatar-list"
import styles from "./collection-item.module.scss"
import {
  ScriptureReference,
  formatScriptureReference,
  getBookLocaliser
} from "@features/scriptures/mappers/scripture-reference-mapper"

export interface CollectionItemProps {
  item: CollectionItemType
}

export const CollectionItem: FunctionComponent<CollectionItemProps> = ({
  item
}) => {
  const {
    link,
    title,
    subtitle: subtitleProp,
    teachers,
    image,
    readingTime,
    type,
    scriptureReference
  } = item

  const { formatMessage } = useIntl()
  const getLocalisedBook = getBookLocaliser(formatMessage)

  const shouldUseScriptureReference = type === "devotional"

  const subtitle =
    scriptureReference && shouldUseScriptureReference
      ? formatScriptureReference(
          scriptureReference as ScriptureReference,
          getLocalisedBook
        )
      : subtitleProp

  return (
    <div className={styles.collectionItem}>
      <Link to={link}>
        <ItemImage className={styles.image} src={image} />
        <div className={styles.additionalInfo}>
          <Icon
            className={styles.icon}
            variant={collectionItemIconVariant[type]}
          />
          {Boolean(readingTime) && (
            <span>
              <FormattedMessage
                id="general-reading-time-short"
                values={{ time: readingTime }}
              />
            </span>
          )}
        </div>
        <h2 className={styles.title}>{title}</h2>
        {subtitle && <div className={styles.avatars}>{subtitle}</div>}
        {!subtitle && teachers.length > 0 && (
          <div className={styles.avatars}>
            <AvatarList avatars={teachers} size="xs" showNames />
          </div>
        )}
        <div className={styles.mobileInfo}>
          <div className={styles.mobileMeta}>
            {teachers.length > 0 && (
              <div className={styles.firstTeacherName}>{teachers[0].name}</div>
            )}
            {Boolean(readingTime) && (
              <span>
                <FormattedMessage
                  id="general-reading-time-short"
                  values={{ time: readingTime }}
                />
              </span>
            )}
          </div>
          <Icon
            className={styles.icon}
            variant={collectionItemIconVariant[type]}
          />
        </div>
      </Link>
    </div>
  )
}
